import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Flex, Grid, Stack } from '../../components/Core';
import FormationCard from '../../components/FormationCard';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import InstituteNewsletter from '../../components/Newsletter/InstituteNewsletter';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import compact from '../../utils/helpers/compact';
import groupBy from '../../utils/helpers/groupBy';
import orderBy from '../../utils/helpers/orderBy';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';
import { tw } from '../../utils/tailwind';

type IInstituteTemplate = PageProps<Queries.PageInstituteQuery, IPageContext>;

const Institute: FCWithClassName<IInstituteTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData =
    'contenuInstitute' in pageData.template
      ? pageData.template.contenuInstitute
      : null;
  const formationPageTemplateData =
    'contenuFormation' in data.formationPageTemplate.template
      ? data.formationPageTemplate.template.contenuFormation
      : null;
  if (
    !pageData ||
    !pageContext.defaultLanguage ||
    !pageTemplateData ||
    !formationPageTemplateData
  )
    return null;
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: pageData.hero?.imageMobile,
  });
  const formations = data.allWpFormation?.nodes;
  const serializedNewsletterQuestions = compact(
    pageContext.siteSettings?.newsletterForm?.questions.map((question) => {
      if (!['first-name', 'last-name', 'email'].includes(question.slug))
        return null;
      const options = question.choixDisponibles?.map((choice) => ({
        checked: choice.checked,
        label: choice.label,
        name: choice.name,
      }));
      return {
        label: question.label,
        name: question.slug,
        options,
        required: question.obligatoire,
        toggleButtonText: question.toggletext,
        type: question.questionType,
      };
    })
  );
  const formationsGroupedByPastOrFuture = groupBy(formations, (formation) => {
    if (!formation) return undefined;
    return new Date(formation.informationsFormation.dateRaw) < new Date() ? 'past' : 'future';
  });
  const pastFormationsSortedFromClosestToFarthest = orderBy(
    formationsGroupedByPastOrFuture.past,
    (formation) => formation.informationsFormation.dateRaw,
    'desc'
  );
  const { facebookInstitute, instagramInstitute } =
    data.allWpConfiguration.nodes[0].configuration;
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
      theme="institute"
      hideNewsletter
    >
      <SEO pageData={pageData} />
      <Stack justify="center" className="space-y-gap">
        <Hero
          type={HERO_TYPE.STATIC}
          couleurTitre={pageData.hero?.couleurTitre}
          heading={pageData.hero?.titre}
          tagline={pageData.hero?.tagline}
          backgroundImage={heroImageWithArtDirection}
        />
        <SectionWithTitle
          heading={pageTemplateData.institute.introduction.title}
          text={pageTemplateData.institute.introduction.description}
        >
          <Grid className={tw('w-full grid-cols-1 gap-4 justify-items-center', formationsGroupedByPastOrFuture.future?.length > 1 ? 'lg:grid-cols-2' : '')}>
            {formationsGroupedByPastOrFuture.future?.map((formation) => {
              if (!formation) return null;
              return (
                <FormationCard
                  key={formation.id}
                  buyCtaLabel={pageTemplateData.institute.productLabel}
                  formation={formation}
                />
              );
            })}
          </Grid>
        </SectionWithTitle>
        <SectionWithTitle
          heading={pageTemplateData.institute.pastCourses.title}
          text={pageTemplateData.institute.pastCourses.description}
        >
          <Grid className={tw('w-full grid-cols-1 gap-4 justify-items-center', pastFormationsSortedFromClosestToFarthest?.length > 1 ? 'lg:grid-cols-2' : '')}>
            {pastFormationsSortedFromClosestToFarthest?.map((formation) => {
              if (!formation) return null;
              return (
                <FormationCard
                  key={formation.id}
                  buyCtaLabel={pageTemplateData.institute.productLabel}
                  formation={formation}
                />
              );
            })}
          </Grid>
        </SectionWithTitle>
        <SectionWithTitle
          heading={formationPageTemplateData.sectionSocial.title}
          text={formationPageTemplateData.sectionSocial.description}
        >
          <Stack className="mx-auto space-y-8">
            <Flex className="justify-center mx-auto">
              <a
                href={facebookInstitute}
                target="_blank"
                className="border-b-0 text-text"
                rel="noopener noreferrer"
              >
                <FaFacebookF className="w-10 h-10" />
              </a>
              <a
                href={instagramInstitute}
                target="_blank"
                className="border-b-0 text-text"
                rel="noopener noreferrer"
              >
                <FaInstagram className="w-10 h-10" />
              </a>
            </Flex>
            <InstituteNewsletter
              feedbackMessages={{
                error: pageContext.siteSettings.newsletterForm?.errorMsg,
                success: pageContext.siteSettings.newsletterForm?.successMsg,
                update: pageContext.siteSettings.newsletterForm?.updateMsg,
              }}
              languageCode={pageContext.currentLanguage?.code}
              questions={serializedNewsletterQuestions}
              submitButtonLabel={
                pageContext.siteSettings.newsletterForm?.texteBoutonInscription
              }
            />
          </Stack>
        </SectionWithTitle>
      </Stack>
    </Layout>
  );
};

export default Institute;

export const query = graphql`
  query PageInstitute($id: String, $currentLanguageSlug: String) {
    formationPageTemplate: wpPage(
      language: { slug: { eq: $currentLanguageSlug } }
      template: { templateName: { eq: "Page Formation" } }
    ) {
      title
      template {
        ... on WpTemplate_PageFormation {
          contenuFormation {
            addCartCtaLabel
            backToInstituteCtaLabel
            detailsLabel
            backToInstituteCtaLink {
              ... on WpPage {
                uri
              }
            }
            sectionSocial {
              title
              description
            }
          }
          templateName
        }
      }
    }
    allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          facebookInstitute
          instagramInstitute
        }
      }
    }
    wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      hero {
        couleurTitre
        tagline
        titre
        imageMobile {
          ...MediaItemFragment
          localFile {
            ...MobileHeroImage
          }
        }
      }
      template {
        ... on WpTemplate_PageInstitute {
          contenuInstitute {
            institute {
              introduction {
                title
                description
              }
              pastCourses {
                title
                description
              }
              productLabel
            }
          }
        }
      }
    }
    allWpFormation(sort: { fields: informationsFormation___date, order: ASC }) {
      nodes {
        id
        title
        link
        slug
        informationsFormation {
          centre
          date(formatString: "DD.MM.Y H:mm")
          dateRaw: date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
          duree
          language
          price
          shortDescription
        }
        featuredImage {
          node {
            ...MediaItemFragment
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  height: 200
                  breakpoints: [400]
                  
                  layout: CONSTRAINED
                )
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;
