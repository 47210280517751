import { FaHourglassEnd } from '@react-icons/all-files/fa/FaHourglassEnd';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { IoMdPin } from '@react-icons/all-files/io/IoMdPin';
import { MdLanguage } from '@react-icons/all-files/md/MdLanguage';
import React from 'react';

import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import { Flex } from '../Core/Flex';
import IconWithText from '../IconWithText';
import Image from '../Image';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

export type IWordpressFormationEntry = Queries.PageInstituteQuery['allWpFormation']['nodes'][number];
interface IFormationCardProps {
  buyCtaLabel: string | undefined;
  formation: IWordpressFormationEntry;
}

const FormationCard: FCWithClassName<IFormationCardProps> = (props) => {
  const { buyCtaLabel, formation, ...rest } = props;
  const bgImage = serializeImageForImageComponent({
    image: formation.featuredImage?.node,
  });
  const hiddenLabel = 'Hidden';
  return (
    <Flex
      className="w-full max-w-[400px] h-max relative "
      align="center"
      direction="column"
      {...rest}
    >
      <Image image={bgImage} className="w-full" />
      <Flex
        className="z-10 bg-backgroundMain w-[100%] p-8 relative"
        align="center"
        direction="column"
      >
        <StyledLink
          to={formation.link}
          className="hover:!text-primaryInstitute"
        >
          <Text
            variant={TEXT_STYLES.H4}
            className="text-center !text-4 lg:!text-5"
          >
            {formation.title}
          </Text>
        </StyledLink>
        <Text variant={TEXT_STYLES.P} className="text-center mt-4 !text-base">
          {formation.informationsFormation.shortDescription}
        </Text>
        <Flex
          className="self-start w-full mt-4 pt-4 relative border-t border-solid border-t-black/[.01]"
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
        >
          <IconWithText
            isInline
            icon={FaRegCalendarAlt}
            description={formation.informationsFormation.date}
            iconClassName="w-6 h-6"
            textClassName="!text-xl mt-1.5 uppercase"
            className="mb-0"
          />
          <IconWithText
            isInline
            icon={FaHourglassEnd}
            description={formation.informationsFormation.duree.toString()}
            iconClassName="w-6 h-6"
            textClassName="!text-xl mt-1.5 uppercase"
            className="mt-2 mb-0 lg:mt-0"
          />
        </Flex>
        <IconWithText
          isInline
          icon={IoMdPin}
          description={formation.informationsFormation.centre}
          iconClassName="w-6 h-6"
          textClassName="mt-1.5 uppercase"
          className="self-start mt-2 mb-0"
        />
        <IconWithText
          isInline
          icon={MdLanguage}
          description={formation.informationsFormation.language}
          iconClassName="w-6 h-6"
          textClassName="mt-1.5 uppercase"
          className="self-start mt-2 mb-10"
        />
        <Flex align="center">
          <StyledLink to={formation.link}>
            <ThemedButton>{buyCtaLabel}</ThemedButton>
          </StyledLink>
        </Flex>
        <ThemedButton
          className="snipcart-add-item !hidden"
          data-item-id={formation.slug}
          data-item-price={formation.informationsFormation.price}
          data-item-has-taxes-included
          data-item-url={formation.link}
          data-item-description={
            formation.informationsFormation.shortDescription
          }
          data-item-image={
            formation.featuredImage?.node.localFile.childImageSharp
              .gatsbyImageData.images.fallback.src
          }
          data-item-name={formation.title}
        >
          {hiddenLabel}
        </ThemedButton>
      </Flex>
    </Flex>
  );
};

export default FormationCard;
